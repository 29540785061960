import React from "react"
import PropTypes from 'prop-types'

import "./header_ylh.scss"

const Header = ({ title, subtitle, image }) => (
    <header className={"sr-header_yhl"}>
        <p className={"subtitle"}></p>
        {image !== undefined ? image : null}
        {title !== null ? <h1 className={"title"}>{title}</h1> : null}
        {subtitle !== null ? <p className={"subtitle"}>{subtitle}</p> : null}
    </header>
)

Header.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    image: PropTypes.node,
}

Header.defaultProps = {
    title: "",
    subtitle: ""
}

export default Header
